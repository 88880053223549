<template>
<div class="system">
		<img src="../assets/image/404.png"/>
	<div class="title">
		<h2>页面走丢了...</h2>
		<h4>远方的朋友你好！非常抱歉，您所请求的页面不存在！</h4>
		<h4>请仔细检查您输入的网址是否正确。</h4>
	</div>
	<div class="back" @click="back">返回首页</div>	
</div>
</template>
<script>
export default {
	methods:{
		back(){
			this.$router.push('/')
		}
	}
}
</script>

<style scoped>
* {
	word-wrap:break-word
}
.back{
	cursor: pointer;
	width: 150px;
	height: 50px;
	text-align: center;
	line-height: 45px;
	margin: 20px auto;
	border: 1px solid #FFFFFF;
	border-radius: 50px;
	color: #FFFFFF;
}
html,body,h1,h2,h3,h4,h5,h6,hr,p,iframe,dl,dt,dd,ul,ol,li,pre,form,button,input,textarea,th,td,fieldset {
	color:#666;
	margin:0;
	padding:0;
	font-weight: normal;
}
ul,ol,dl {
	list-style-type:none
}
html,body {
	*position:static;
}
html {
	font-family:sans-serif;
	-webkit-text-size-adjust:100%;
	-ms-text-size-adjust:100%
}
address,caption,cite,code,dfn,em,th,var {
	font-style:normal;
	font-weight:400
}
input,button,textarea,select,optgroup,option {
	font-family:inherit;
	font-size:inherit;
	font-style:inherit;
	font-weight:inherit
}
input,button {
	overflow:visible;
	vertical-align:middle;
	outline:none
}
body,th,td,button,input,select,textarea {
	font-family:"Microsoft Yahei","Hiragino Sans GB","Helvetica Neue",Helvetica,tahoma,arial,Verdana,sans-serif,"WenQuanYi Micro Hei","\5B8B\4F53";
	font-size:12px;
	color:#333;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale
}
body {
	line-height:1.6;
	background: #7395ff;
}
h1,h2,h3,h4,h5,h6 {
	font-size:100%
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
a,area {
	outline:none;
	/* blr:expression(this.onFocus=this.blur()) */
}
a {
	text-decoration:none;
	cursor:pointer
}
a:hover {
	text-decoration:none;
	outline:none
}
a.ie6:hover {
	zoom:1
}
a:focus {
	outline:none
}
a:hover,a:active {
	outline:none
}
:focus {
	outline:none
}
sub,sup {
	vertical-align:baseline
}
button,input[type="button"],input[type="submit"] {
	line-height:normal !important;
}
img {
	border:0;
	vertical-align:middle
}
a img,img {
	-ms-interpolation-mode:bicubic
}
.img-responsive {
	max-width:100%;
	height:auto
}
.clears{ 
	clear:both;
}

header,footer,section,aside,details,menu,article,section,nav,address,hgroup,figure,figcaption,legend {
	display:block;
	margin:0;
	padding:0
}
time {
	display:inline
}
audio,canvas,video {
	display:inline-block;
	*display:inline;
	*zoom:1
}
audio:not([controls]) {
	display:none
}
legend {
	width:100%;
	margin-bottom:20px;
	font-size:21px;
	line-height:40px;
	border:0;
	border-bottom:1px solid #e5e5e5
}
legend small {
	font-size:15px;
	color:#999
}
svg:not(:root) {
	overflow:hidden
}
fieldset {
	border-width:0;
	padding:0.35em 0.625em 0.75em;
	margin:0 2px;
	border:1px solid #c0c0c0
}
input[type="number"]::-webkit-inner-spin-button,input[type="number"]::-webkit-outer-spin-button {
	height:auto
}
input[type="search"] {
	-webkit-appearance:textfield;
	/* 1 */-moz-box-sizing:content-box;
	-webkit-box-sizing:content-box;
	/* 2 */box-sizing:content-box
}
input[type="search"]::-webkit-search-cancel-button,input[type="search"]::-webkit-search-decoration {
	-webkit-appearance:none
}
.cl:after,.clearfix:after {
	content:".";
	display:block;
	height:0;
	clear:both;
	visibility:hidden
}
.cl,.clearfix {
	zoom:1
}
.fl{
	float: left;
}
.fr{
	float: right;
}
.system{
    margin: 0 auto;
    text-align: center;
    padding-top: 140px;
    height: 100%;
    background-color: skyblue;
}
.system img{
	text-align: center;
	    width: 500px;
}
.title{
	
}
.title h2{
	color: #fff;
    font-size: 36px;
    letter-spacing: 4px;
    text-align: center;
    line-height: 120px;
}
.title h4
{
	color: #fff;
    font-size: 18px;
    /* font-weight: bold; */
    line-height: 38px;
    text-align: center;
}
</style>